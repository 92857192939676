















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Sheet } from '@/types/cms/components/';

/**
 * CMS sheet component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class CMSComponentSheet extends Vue {
    // prop with sheet data
    @Prop({ type: Object as () => Sheet, required: true}) readonly sheetData: Sheet | undefined;
}
